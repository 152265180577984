// The purpose of this function is to load asynchronously the segment library
// while still being able to call analytics.track() before it is loaded.
//
// To acheive this goal this function creates a temporary object "analytics".
// This temporary object stores all the calls to analytics.track().
// Then it loads the "real" segment segment library.
// When the "real" segment library is loaded it replays all the events stored.
export const initializeSegment = (callback) => {
  // Create a queue, but don't obliterate an existing one!
  const analytics = window.analytics = window.analytics || []
  // Check if dataLayer already exists.
  const dataLayer = window.dataLayer = window.dataLayer || []

  // Return permanent instance if already initialized.
  if (analytics.initialized) {
    if (callback) callback()
    return analytics
  }

  // Return temporary instance if the snippet was already invoked.
  if (analytics.invoked && analytics.ready) {
    // Queue callback to be triggered when instance is ready.
    if (callback) analytics.ready(callback)
    return analytics
  }

  // Invoked flag, to make sure the snippet is never invoked twice.
  analytics.invoked = true

  // Temporary flag, to check if the window.analytics has already
  // been replaced by the permanent instance.
  // If temporary is true, methods are stubs.
  analytics.temporary = true

  // A list of the methods in Analytics.js to stub.
  analytics.methods = [
    'trackSubmit',
    'trackClick',
    'trackLink',
    'trackForm',
    'pageview',
    'identify',
    'reset',
    'group',
    'track',
    'ready',
    'alias',
    'debug',
    'page',
    'once',
    'off',
    'on',
    'addSourceMiddleware',
    'addIntegrationMiddleware',
    'setAnonymousId',
    'addDestinationMiddleware'
  ]

  // Define a factory to create stubs. These are placeholders
  // for methods in Analytics.js so that you never have to wait
  // for it to load to actually record data. The `method` is
  // stored as the first argument, so we can replay the data.
  analytics.factory = (method) => function() {
    const args = [...arguments]
    args.unshift(method)
    analytics.push(args)
    return analytics
  }

  // For each of our methods, generate a queueing stub.
  for (let i = 0; i < analytics.methods.length; i++) {
    const key = analytics.methods[i]
    analytics[key] = analytics.factory(key)
  }

  // Return mock instance in test mode.
  if (import.meta.env.MODE === 'test') {
    if (callback) callback()
    return analytics
  }

  // If there is no key in the env, we can't load segment.
  if (!import.meta.env.VITE_SEGMENT_WRITE_KEY) {
    if (window.console && console.error) console.error('Missing Segment key.')
    return analytics
  }

  // Define a method to load Analytics.js from our CDN,
  // and that will be sure to only ever load it once.
  analytics.load = (key, options) => {
    const baseUrl = 'https://cdn.segment.com'
    // Create an async script element based on your key.
    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.async = true
    script.src = `${baseUrl}/analytics.js/v1/${key}/analytics.min.js`

    // Insert our script next to the first script element.
    const first = document.getElementsByTagName('script')[0]
    first.parentNode.insertBefore(script, first)
    analytics._loadOptions = options
  }

  // Add a version to keep track of what's in the wild.
  analytics.SNIPPET_VERSION = '4.1.0'

  // Create middleware to send Segment events to Google Tag Manager.
  const gtmMiddleware = ({ payload, next }) => {
    const eventName = `analytics.${payload.action()}`

    // Add event to GTM's event queue.
    dataLayer.push({
      event: eventName,
      ...payload.obj
    })

    // Pass on to the next middleware.
    next(payload)
  }

  // Register middleware.
  analytics.addSourceMiddleware(gtmMiddleware)

  // Store event in GTM for initialization.
  dataLayer.push({ event: 'analytics.called' })
  analytics.ready(() => dataLayer.push({ event: 'analytics.loaded' }))

  // Add callback after initialization.
  if (callback) analytics.ready(callback)

  // Load Analytics.js with your key, which will automatically
  // load the tools you've enabled for your account. Boosh!
  analytics.load(import.meta.env.VITE_SEGMENT_WRITE_KEY)

  // Return analytics temporary instance.
  return analytics
}
